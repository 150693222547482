import { produce } from "immer";
import { payloadAction, actionFactory, ActionUnion } from "reductser";

const actionMap = {
    setContent: payloadAction<ContentState["content"]>()
};

export const ContentActions = actionFactory(actionMap, "CONTENT");

export type ContentAction = ActionUnion<typeof ContentActions>;

export interface ContentState {
    content:
        | "home"
        | "explore"
        | "trade"
        | "newsfeed"
        | "upcoming events"
        | "my stable"
        | "account"
        | "sign in"
        | "sign up"
        | "admin"
        | "privacy policy"
        | "terms of service"
        | "community"
        | "offering"
        | "Contact us"; // | 'transaction'
}

export const possibleContents: string[] = [
    "home",
    "explore",
    "trade",
    "newsfeed",
    "upcoming events",
    "my stable",
    "account",
    "sign in",
    "sign up",
    "admin",
    "privacy policy",
    "terms of service",
    "community",
    "offering",
    "Contact us" /*'transaction'*/
];

export const getInitialState = (): ContentState => {
    const path = window.location.pathname.replaceAll("-", " ").replaceAll("/", "");
    let contentname: ContentState["content"] = "home";
    if (possibleContents.includes(path)) {
        contentname = path as ContentState["content"];
    }
    return {
        content: contentname
    };
};

const colorReducer = (state = getInitialState(), action: ContentAction) =>
    produce(state, (draftState) => {
        if (action.reducer === "CONTENT") {
            switch (action.type) {
                case "setContent":
                    window.history.pushState("Triple Crown Trading", "TTC", action.payload.replaceAll(" ", "-"));
                    draftState.content = action.payload;
                    break;
                default:
                    break;
            }
        }
    });

export default colorReducer;
