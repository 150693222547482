import React from "react";
import { BrowserRouter as Router, Route, 
    // Switch, Redirect
 } from "react-router-dom";
import { useSelectFromRedux } from "../utils/_hooks";

// components
import Home from "./home";

function App() {
    return (
        <Router>
            <Home/>
        </Router>
    );
}

export default App;
