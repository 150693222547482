import styled, {keyframes} from "styled-components";
import { Snackbar } from "@material-ui/core";
import { device } from "../../utils";
import { fadeInDown, zoomInUp, fadeInUp, fadeIn } from 'react-animations';

interface FullScreenProps {
    background: string
}

export const FullscreenColor = styled.div`
    width: 100vw;
    min-height: 100vh;
    background: ${(props: FullScreenProps) => props.background};
`;

interface SnackbarProps {
    background: string;
}

interface DivProps {
    text: string;
}

export const CenteredDiv = styled.div`
    display: flex;
    justify-content: center;
    color: ${(props: DivProps) => props.text};
    margin: 20;
    font-size: 100;
    font-family: Allerta;
    align-content: center;
    align-items: center;
`

export const TermsCenteredDiv = styled(CenteredDiv)`
    margin-right: 10%;
    margin-left: 10%;
    margin-bottom: 10px;
    text-align: left;
    justify-content: left;
`

export const Toast = styled(Snackbar)`
    .MuiSnackbarContent-root {
        background: ${(props: SnackbarProps) => props.background} !important;
        justify-content: center;
    }
    @media ${device.mobile} {
        margin-top: 80px;
    }
`;

export const TopLogo = styled.img`
    padding-left: 10px;
    width: 90px;
    cursor: pointer;

`

// export const LogoContainer = styled.div`
//     cursor: pointer;
//     position: fixed;
//     top: 16px;
//     left: 20px;
//     width: 40px;
//     height: 37px;
//     z-index: 20;

//     :active {
//         width: 42px;
//         height: 39px;
//         top: 15px;
//         left: 19px;
//     }

//     @media ${device.mobile} {
//         top: 29px;

//         :active {
//             top: 28px;
//         }
//     }
// `;

// export const CurrentDisplayContainer = styled.div`
//     z-index: 20;
//     position: fixed;
//     top: 70px;
//     right: 20px;
// `;

// export const ReportToast = styled(Snackbar)`
//     .MuiSnackbarContent-root {
//         background: ${(props: SnackbarProps) => props.background} !important;
//     }
//     @media ${device.mobile} {
//         margin-top: 80px;
//     }
// `;


export const HorizontalBox = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
`

export const VerticalBox = styled.div`
    display: flex;
    justify-content: space-around;
    align-text: center;
    align-content: center;
    flex-direction: column;
`

export const CenterText = styled.div`
    text-align: center;
    width: 250px;
    display: flex;
    justify-content: center;
    font-size: 20;
    margin-top: 10px;
`

const fadeDownAnimation = keyframes`${fadeInDown}`;

export const FadeDownImage = styled.div`
    animation: 1s ${fadeDownAnimation};
    background: none;
`

const zoomAnimation = keyframes`${zoomInUp}`;

export const ZoomImage = styled.div`
    animation: 1s ${zoomAnimation};
`

const fadeAnimation = keyframes`${fadeIn}`;

export const FadeImage = styled.div`
    animation: 1s ${fadeAnimation};
`

export const FadeImageDelay = styled.div`
    animation-delay: 5s;
    animation: 1s ${fadeAnimation};
`

export const CenteredText = styled.div`
    width: 70%;
    flex-direction: column;
    text-align: center;
    display: flex;
    justify-content: center;
    color: ${(props: DivProps) => props.text};
    margin: 20;
    font-size: 100;
    font-family: Allerta;
    margin-left: 15%
`