import React, { useEffect, useRef, useState } from "react";
import "./style.css";
// redux
import { useSelectFromRedux, useDispatchPromise } from "../../utils/_hooks";
import { useDispatch } from "react-redux";
import { isMobile, isMobileOnly } from "react-device-detect";
import { ContactButton, ModalMakeOrderButton } from "../_shared";
import { ContentActions } from "../../state/content";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import { PieChart, pieArcLabelClasses } from "@mui/x-charts/PieChart";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import { TextField, createTheme, ThemeProvider } from "@mui/material";
import styled from "styled-components";
import CircularProgress from "@mui/material/CircularProgress";

//components

export default (_props: any) => {
    const dispatch = useDispatch();

    const isMobileOnly = window.outerWidth < 500;

    const images = [
        "https://tctrading.s3.amazonaws.com/RiverMaidContent/2.JPG",
        "https://tctrading.s3.amazonaws.com/RiverMaidContent/3.JPG",
        "https://tctrading.s3.amazonaws.com/RiverMaidContent/4.JPG",
        "https://tctrading.s3.amazonaws.com/RiverMaidContent/5.JPG",
        "https://tctrading.s3.amazonaws.com/RiverMaidContent/7.JPG"
    ];

    // Define properties for the slider
    const properties = {
        transitionDuration: 500,
        easing: "ease",
        indicators: () => <Dot />, // Custom dot component
        arrows: false
    };

    // Styled component for full-width container
    const SliderContainer = styled.div`
        width: 100vw; // Full width of viewport
        overflow: hidden; // Hide any overflow for a clean look
    `;

    // Style each slide to take full width and height
    const SlideWrapper = styled.div`
        height: 100vh; // Full height of viewport (adjust as needed)
        display: flex;
        align-items: center;
        justify-content: center;
        background-size: cover;
        background-position: center;
    `;

    const Dot = styled.div`
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #333;
        margin: 0 5px;
        transition: background-color 0.3s;

        &.active {
            background-color: #ccc;
        }
    `;

    const slideRef = React.useRef(null);

    const currencyFormatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0
    });

    interface PieData {
        id: number;
        value: number;
        label: string;
    }

    const data = [
        { id: 0, value: 90000, label: `${currencyFormatter.format(90000)}` },
        { id: 1, value: 57118.42, label: `${currencyFormatter.format(57118)}` },
        { id: 2, value: 11891.2, label: `${currencyFormatter.format(11891)}` },
        { id: 3, value: 9000, label: `${currencyFormatter.format(9000)}` },
        { id: 4, value: 4500, label: `${currencyFormatter.format(4500)}` },
        { id: 5, value: 4500, label: `${currencyFormatter.format(4500)}` },
        { id: 6, value: 1990.38, label: `${currencyFormatter.format(1990.38)}` }
    ];

    const ExpandIcon = () => (
        <>
            <svg width="33" height="16" viewBox="0 0 33 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M1 1.50977L16.5 14.5098L32 1.50977"
                    stroke="#898989"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        </>
    );

    useEffect(() => {
        window.scrollTo(0, 0);
        setInvest(false);
    }, []);

    const theme = createTheme();

    const CustomTextField = styled(TextField)(({ theme }) => ({
        "& .MuiOutlinedInput-root": {
            borderRadius: 0, // Square corners
            backgroundColor: "white", // Gray 200 background
            "& fieldset": {
                border: `1px solid ${theme.palette.grey[200]}` // Outlined with gray
            },
            "&:hover fieldset": {
                border: `1px solid #064A00` // Maintain outline on hover
            },
            "&.Mui-focused fieldset": {
                border: `1px solid #064A00` // Maintain outline when focused
            }
        },
        "& input": {
            fontFamily: "Abhaya Libre, sans-serif", // Abhaya Libre font
            fontSize: "20px", // 20 point font size
            color: theme.palette.grey[700] // Gray 700 text color
        },
        height: "44px", // Set height to 44 pixels
        "& .MuiInputBase-root": {
            height: "44px" // Ensure the input area is also 44 pixels high
        }
    }));

    interface PasswordModalProps {
        setInvest: (value: boolean) => void;
    }

    const [invest, setInvest] = useState<Boolean>(false);

    const [isScrolled, setIsScrolled] = useState(false);

        useEffect(() => {
            const handleScroll = () => {
                setIsScrolled(window.scrollY > 0);
            };

            window.addEventListener('scroll', handleScroll);
            return () => window.removeEventListener('scroll', handleScroll);
        }, []);

    const PasswordModal: React.FC<PasswordModalProps> = ({ setInvest }) => {
        const [password, setPassword] = React.useState("");
        const [granted, setGranted] = React.useState(false);

        const wait = (ms: number): Promise<void> => {
            return new Promise((resolve) => setTimeout(resolve, ms));
        };

        useEffect(() => {
            const checkPassword = async () => {
                if (password == "woodford" || password == "Woodford") {
                    setGranted(true);
                    await wait(1000);
                    setInvest(false);
                    setGranted(false);
                    window.location.href = "https://triplecrowntrading.deal.tribexa.com/deal/create-investment";
                }
            };

            checkPassword();
        }, [password]);


        return (
            <div
                style={{
                    height: "100vh",
                    width: "100vw",
                    position: "fixed",
                    overflowY: "hidden",
                    zIndex: 10
                }}
                onClick={() => setInvest(false)}
            >
                <div
                    style={{
                        position: "fixed",
                        width: isMobileOnly ? "90%" : "500px",
                        height: "150px",
                        background: "white",
                        borderRadius: "10px",
                        border: "solid #D9D9D9",
                        top: "calc(50% - 75px)",
                        left: isMobileOnly ? "5%" : "calc(50% - 250px)",
                        zIndex: 11
                    }}
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    {granted && (
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "flex-start"
                            }}
                        >
                            <div
                                style={{
                                    fontFamily: "Libre Caslon Text",
                                    fontSize: "20px",
                                    textAlign: "center",
                                    marginTop: "20px",
                                    paddingLeft: "20px",
                                    paddingRight: "20px",
                                    marginBottom: "20px"
                                }}
                            >
                                Access Granted! Taking You To the Investor Portal.
                            </div>
                            <CircularProgress />
                        </div>
                    )}
                    {!granted && (
                        <>
                            <CloseIcon
                                style={{ position: "absolute", right: "5px", cursor: "pointer" }}
                                onClick={() => setInvest(false)}
                            />
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "flex-start"
                                }}
                            >
                                <div
                                    style={{
                                        fontFamily: "Libre Caslon Text",
                                        fontSize: "20px",
                                        textAlign: "center",
                                        marginTop: "20px"
                                    }}
                                >
                                    Enter Early Access Password
                                </div>
                                <ThemeProvider theme={theme}>
                                    <form>
                                        <CustomTextField
                                            style={{
                                                width: "300px",
                                                marginTop: "30px"
                                            }}
                                            id="e.g. TCrownTrading123"
                                            label=""
                                            variant="outlined"
                                            placeholder="Enter Password"
                                            fullWidth
                                            value={password}
                                            autoFocus
                                            onChange={(event) => setPassword(event.target.value)}
                                        />
                                    </form>
                                </ThemeProvider>
                            </div>
                        </>
                    )}
                </div>
            </div>
        );
    };

    return (
        <>
            {invest && <PasswordModal setInvest={setInvest} />}
            <div
                className="element-o"
                style={{ filter: invest ? "blur(4px)" : "none", overflowY: invest ? "hidden" : "inherit" }}
            >
                <img
                    className={isMobileOnly ? "mobile-horsepic" : "horsepic"}
                    alt="Horse Picture"
                    src="https://tctrading.s3.amazonaws.com/RiverMaidContent/6.JPG"
                />
                {isMobileOnly && <img
                    className={isMobileOnly ? "mobilelogo" : "logo"}
                    alt="Logo"
                    src="https://tctrading.s3.us-east-1.amazonaws.com/whitehorse.png"
                    onClick={() => dispatch(ContentActions.setContent("home"))}
                    style={{ cursor: "pointer" }}
                />}
                {!isMobileOnly && (
                    <div
                        style={{
                            height: '70px',
                            width: '100vw',
                            position: 'fixed',
                            top: 0,
                            zIndex: 20,
                            transition: 'background 0.5s, borderBottom 0.5s',
                            background: isScrolled ? '#202409' : 'transparent',
                            // borderBottom: isScrolled ? 'solid #AEAEAE 1px' : 'none',
                        }}
                    >
                        <img
                            className={isMobileOnly ? "mobilelogo" : "logo"}
                            alt="Logo"
                            src="https://tctrading.s3.us-east-1.amazonaws.com/whitehorse.png"
                            onClick={() => dispatch(ContentActions.setContent("home"))}
                            style={{ cursor: "pointer" }}
                        />
                        <div
                            className="text-wrapper"
                            onClick={() => dispatch(ContentActions.setContent("home"))}
                            style={{ cursor: "pointer" }}
                        >
                            TRIPLE CROWN TRADING
                        </div>
                        <div className="investnowtop"  style={{top: '15px'}}>
                            <ModalMakeOrderButton
                                style={{
                                    position: "fixed",
                                    right: "40px",
                                    alignSelf: "center",
                                    width: "192px",
                                    fontSize: "16px",
                                    color: "white",
                                    fontFamily: "Libre Caslon Text",
                                    textTransform: "none",
                                    fontStyle: "italic",
                                    borderRadius: "25px",
                                    boxShadow: "none",
                                    height: "40px",
                                    background: isScrolled? "#925F22" : "#202409",
                                    transition: '0.5s',
                                    cursor: "pointer",
                                    zIndex: 20
                                }}
                                variant="contained"
                                onClick={() =>
                                    (window.location.href =
                                        "https://triplecrowntrading.deal.tribexa.com/deal/create-investment")
                                }
                                // onMouseEnter={(event) => {
                                //     const target = event.target as HTMLElement;
                                //     target.style.backgroundColor = isScrolled? "#7A4F33" : "black";
                                // }}
                                // onMouseLeave={(event) => {
                                //     const target = event.target as HTMLElement;
                                //     target.style.backgroundColor = isScrolled ? "#925F22" : "#202409";
                                // }}
                                onMouseEnter={(event) => {
                                    const target = event.target as HTMLElement;
                                    target.style.backgroundColor = isScrolled? "#7A4F33" : "black";
                                }}
                                onMouseLeave={(event) => {
                                    const target = event.target as HTMLElement;
                                    target.style.backgroundColor = isScrolled ? "#925F22" : "#202409";
                                }}
                            >
                                Invest now
                            </ModalMakeOrderButton>
                        </div>
                        <div className="contactustop"  style={{top: '15px'}}>
                            <ContactButton
                                style={{
                                    alignSelf: "center",
                                    width: "192px",
                                    fontSize: "16px",
                                    color: "white",
                                    fontFamily: "Libre Caslon Text",
                                    textTransform: "none",
                                    // fontStyle: "italic",
                                    borderRadius: "25px",
                                    boxShadow: "none",
                                    fontWeight: 30,
                                    gap: "8px",
                                    padding: "var(--space-xxxs, 4px) var(--space-s, 23.1px)",
                                    height: "40px"
                                }}
                                variant="text"
                                onClick={() => dispatch(ContentActions.setContent("Contact us"))}
                                onMouseEnter={(event) => {
                                    const target = event.target as HTMLElement;
                                }}
                                onMouseLeave={(event) => {
                                    const target = event.target as HTMLElement;
                                }}
                            >
                                Contact us
                            </ContactButton>
                        </div>
                    </div>
                )}
                <div className={isMobileOnly ? "mobile-landing-2" : "landing-2"}>
                    <h1 className="h-1">Tapit Colt</h1>
                    <p className={isMobileOnly ? "mobile-p" : "p"}>A $450,000 colt with a pedigree of champions.</p>
                    <div className="join-the-waitlist">
                        <ModalMakeOrderButton
                            style={{
                                alignSelf: "center",
                                width: "252px",
                                fontSize: "20px",
                                color: "white",
                                fontFamily: "Libre Caslon Text",
                                textTransform: "none",
                                fontStyle: "italic",
                                borderRadius: "25px",
                                boxShadow: "none",
                                gap: "8px",
                                height: "50px",
                                background: "#202409"
                            }}
                            variant="contained"
                            onClick={() =>
                                (window.location.href =
                                    "https://triplecrowntrading.deal.tribexa.com/deal/create-investment")
                            }
                            onMouseEnter={(event) => {
                                const target = event.target as HTMLElement;
                                target.style.backgroundColor = "black";
                            }}
                            onMouseLeave={(event) => {
                                const target = event.target as HTMLElement;
                                target.style.backgroundColor = "#202409";
                            }}
                        >
                            Invest now
                        </ModalMakeOrderButton>
                    </div>
                </div>
                <div
                    style={{
                        width: "100%",
                        height: "88px",
                        background: "#F2F2F2",
                        textAlign: "center",
                        color: "black",
                        fontFamily: "Libre Caslon Text",
                        fontStyle: "italic",
                        fontWeight: 500,
                        lineHeight: "32px",
                        paddingTop: "25px",
                        fontSize: "20px"
                    }}
                >
                    Limited time only.
                </div>
                <div
                    style={{
                        minHeight: isMobileOnly ? "1270px" : "650px",
                        display: "flex",
                        flexDirection: isMobileOnly ? "column" : "row-reverse",
                        alignContent: "center",
                        justifyContent: isMobileOnly ? "space-between" : "space-evenly",
                        alignItems: "bottom",
                        padding: isMobileOnly ? "70px 30px" : "70px 80px"
                    }}
                >
                    <img
                        alt="horse"
                        src="https://tctrading.s3.amazonaws.com/RiverMaidContent/1.png"
                        style={{
                            height: isMobileOnly ? "auto" : "500px"
                        }}
                    />
                    <div
                        style={{
                            minHeight: "500px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-end",
                            alignContent: "center",
                            padding: "0px 0px",
                            paddingRight: isMobileOnly ? "0px" : "80px"
                        }}
                    >
                        <div
                            style={{
                                fontFamily: "Libre Caslon Text",
                                fontSize: isMobileOnly ? "55px" : "60px",
                                marginBottom: "50px",
                            }}
                        >
                            The colt of a champion.
                        </div>
                        <div
                            style={{
                                fontFamily: "Abhaya Libre",
                                fontSize: "24px",
                                lineHeight: "27px"
                            }}
                        >
                            This chestnut colt is sired by Tapit, one of the most influential stallions of the past half-century. Tapit has produced 32 Grade 1 winners, 40 yearlings sold for $1 million or more at auction, and over $211 million in racetrack earnings, making him the highest-earning sire in North American history. Paired with River Maid, a Sovereign Award Champion Female Sprinter, this colt offers a rare opportunity to own a thoroughbred with a world-class lineage.
                        </div>
                        <ModalMakeOrderButton
                            style={{
                                alignSelf: isMobileOnly ? "center" : "left",
                                width: isMobileOnly ? "100%" : "252px",
                                fontSize: "20px",
                                color: "white",
                                fontFamily: "Libre Caslon Text",
                                textTransform: "none",
                                fontStyle: "italic",
                                borderRadius: "25px",
                                boxShadow: "none",
                                // fontWeight: 500,
                                gap: "8px",
                                height: "50px",
                                marginTop: "40px",
                                background: "#925F22"
                            }}
                            variant="contained"
                            onClick={() => window.location.href = 'https://secure.keeneland.com/sales/k223/pdfs/293.pdf'}
                            onMouseEnter={(event) => {
                                const target = event.target as HTMLElement;
                                target.style.backgroundColor = "#7A4F33";
                            }}
                            onMouseLeave={(event) => {
                                const target = event.target as HTMLElement;
                                target.style.backgroundColor = "#925F22";
                            }}
                        >
                            View pedigree
                        </ModalMakeOrderButton>
                    </div>
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: isMobileOnly ? "column" : "row",
                        justifyContent: "space-evenly"
                    }}
                >
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                        <div
                            style={{
                                color: "#575757",
                                fontFamily: "IBM Plex Mono",
                                fontSize: "16px",
                                fontWeight: 400,
                                textAlign: "center"
                            }}
                        >
                            FOALED
                        </div>
                        <div
                            style={{
                                color: "#000000",
                                fontFamily: "Libre Caslon Text",
                                fontSize: "32px",
                                fontWeight: 400,
                                textAlign: "center",
                                paddingTop: "20px"
                            }}
                        >
                            5/22/22
                        </div>
                        {isMobileOnly && <div style={{ height: "2px", width: "150px", background: "#D9D9D9", alignSelf: "center", marginTop: "30px" }} />}
                    </div>
                    {!isMobileOnly && <div style={{ height: "72px", width: "2px", background: "#D9D9D9" }} />}
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            marginTop: isMobileOnly ? "30px" : "0px"
                        }}
                    >
                        <div
                            style={{
                                color: "#575757",
                                fontFamily: "IBM Plex Mono",
                                fontSize: "16px",
                                fontWeight: 400,
                                textAlign: "center"
                            }}
                        >
                            TRAINER
                        </div>
                        <div
                            style={{
                                color: "#000000",
                                fontFamily: "Libre Caslon Text",
                                fontSize: "32px",
                                fontWeight: 400,
                                textAlign: "center",
                                paddingTop: "20px"
                            }}
                        >
                            Cherie Devaux
                        </div>
                        {isMobileOnly && <div style={{ height: "2px", width: "150px", background: "#D9D9D9", alignSelf: "center", marginTop: "30px" }} />}
                    </div>
                    {!isMobileOnly && <div style={{ height: "72px", width: "2px", background: "#D9D9D9" }} />}
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            marginTop: isMobileOnly ? "30px" : "0px"
                        }}
                    >
                        <div
                            style={{
                                color: "#575757",
                                fontFamily: "IBM Plex Mono",
                                fontSize: "16px",
                                fontWeight: 400,
                                textAlign: "center"
                            }}
                        >
                            RACING CIRCUIT
                        </div>
                        <div
                            style={{
                                color: "#000000",
                                fontFamily: "Libre Caslon Text",
                                fontSize: "32px",
                                fontWeight: 400,
                                textAlign: "center",
                                paddingTop: "20px"
                            }}
                        >
                            Midwest
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        minHeight: isMobileOnly ? "1150px" : "650px",
                        display: "flex",
                        flexDirection: isMobileOnly ? "column" : "row",
                        alignContent: "center",
                        justifyContent: isMobileOnly ? "space-between" : "space-evenly",
                        alignItems: "bottom",
                        padding: isMobileOnly ? "70px 30px" : "70px 80px"
                    }}
                >
                    <img
                        alt="horse"
                        src="https://tctrading.s3.us-east-1.amazonaws.com/utils/cherie+2.png"
                        style={{
                            height: isMobileOnly ? "auto" : "500px"
                        }}
                    />
                    <div
                        style={{
                            minHeight: "500px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-end",
                            alignContent: "center",
                            padding: "0px 0px",
                            paddingLeft: isMobileOnly ? "0px" : "80px"
                        }}
                    >
                        <div
                            style={{
                                fontFamily: "Libre Caslon Text",
                                fontSize: "55px",
                                marginBottom: "50px"
                            }}
                        >
                            Up-and-coming trainer
                        </div>
                        <div
                            style={{
                                fontFamily: "Abhaya Libre",
                                fontSize: "24px",
                                marginBottom: "5px",
                                lineHeight: "27px"
                            }}
                        >
                            Cherie Devaux, a native of the Saratoga area, has quickly risen to prominence as a multiple graded stakes-winning trainer. In just a few years, her operation has seen significant growth, propelling her from a top-75 trainer in earnings to ranking within the top 15. Cherie recently won the Breeders' Cup Dirt Mile and has also claimed victory in the Derby City Distaff, along with a wide variety of other races.
                        </div>
                    </div>
                </div>
                <div style={{ height: "auto" }}>
                    <SliderContainer>
                        <Slide ref={slideRef} {...properties}>
                            {images.map((image, index) => (
                                <SlideWrapper key={index} style={{ backgroundImage: `url(${image})` , height: isMobileOnly ? "500px" : "calc(100vh - 70px)"}} />
                            ))}
                        </Slide>
                    </SliderContainer>
                </div>
                <div
                    style={{
                        width: "100%",
                        height: isMobileOnly ? "1600px" : "990px",
                        padding: isMobileOnly ? "70px 30px" : "0px 115px",
                        marginBottom: "100px"
                    }}
                >
                    <div
                        style={{
                            color: "black",
                            fontFamily: "Libre Caslon Text",
                            fontSize: "60px",
                            fontWeight: "400px",
                            marginBottom: isMobileOnly ? "0px" : "80px",
                            marginTop: isMobileOnly ? "10px" : "60px",
                            textAlign: "center"
                        }}
                    >
                        Investment breakdown
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            marginTop: isMobileOnly ? "10px" : "0px",
                            padding: isMobileOnly ? "0" : "0px 100px",
                            marginBottom: "50px",
                            flexWrap: isMobileOnly ? "wrap" : "nowrap",
                            height: isMobileOnly ? "200px" : "auto"
                        }}
                    >
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                            <div
                                style={{
                                    color: "#575757",
                                    fontFamily: "IBM Plex Mono",
                                    fontSize: "16px",
                                    fontWeight: 400,
                                    marginTop: isMobileOnly ? "30px" : "0",
                                    textAlign: "center"
                                }}
                            >
                                SHARE PRICE
                            </div>
                            <div
                                style={{
                                    color: "#000000",
                                    fontFamily: "Libre Caslon Display",
                                    fontSize: "50px",
                                    fontWeight: 400,
                                    textAlign: "center",
                                    paddingTop: "20px"
                                }}
                            >
                                $500
                            </div>
                        </div>
                        {!isMobileOnly && <div style={{ height: "72px", width: "2px", background: "#D9D9D9" }} />}
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                                marginTop: isMobileOnly ? "30px" : "0px"
                            }}
                        >
                            <div
                                style={{
                                    color: "#575757",
                                    fontFamily: "IBM Plex Mono",
                                    fontSize: "16px",
                                    fontWeight: 400,
                                    textAlign: "center"
                                }}
                            >
                                TOTAL OFFERING
                            </div>
                            <div
                                style={{
                                    color: "#000000",
                                    fontFamily: "Libre Caslon Display",
                                    fontSize: "50px",
                                    fontWeight: 400,
                                    textAlign: "center",
                                    paddingTop: "20px"
                                }}
                            >
                                $179,000
                            </div>
                        </div>
                        {!isMobileOnly && <div style={{ height: "72px", width: "2px", background: "#D9D9D9" }} />}
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                                marginTop: isMobileOnly ? "30px" : "0px"
                            }}
                        >
                            <div
                                style={{
                                    color: "#575757",
                                    fontFamily: "IBM Plex Mono",
                                    fontSize: "16px",
                                    fontWeight: 400,
                                    textAlign: "center"
                                }}
                            >
                                TCT OWNERSHIP
                            </div>
                            <div
                                style={{
                                    color: "#000000",
                                    fontFamily: "Libre Caslon Display",
                                    fontSize: "50px",
                                    fontWeight: 400,
                                    textAlign: "center",
                                    paddingTop: "20px"
                                }}
                            >
                                20%
                            </div>
                        </div>
                        {!isMobileOnly && <div style={{ height: "72px", width: "2px", background: "#D9D9D9" }} />}
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                                marginTop: isMobileOnly ? "30px" : "0px"
                            }}
                        >
                            <div
                                style={{
                                    color: "#575757",
                                    fontFamily: "IBM Plex Mono",
                                    fontSize: "16px",
                                    fontWeight: 400,
                                    textAlign: "center"
                                }}
                            >
                                EQUITY PER SHARE
                            </div>
                            <div
                                style={{
                                    color: "#000000",
                                    fontFamily: "Libre Caslon Display",
                                    fontSize: "50px",
                                    fontWeight: 400,
                                    textAlign: "center",
                                    paddingTop: "20px"
                                }}
                            >
                                ~ 0.06%
                            </div>
                        </div>
                    </div>
                    <div
                        style={{
                            height: isMobileOnly ? "1000px" : "600px",
                            display: "flex",
                            flexDirection: isMobileOnly ? "column" : "row",
                            justifyContent: "space-between",
                            alignContent: "center",
                            marginTop: isMobileOnly ? "30px" : "0"
                        }}
                    >
                        <PieChart
                            width={isMobileOnly ? window.outerWidth - 60 : 700}
                            height={isMobileOnly ? window.outerWidth - 60 : 600}
                            legend={{ hidden: true }}
                            colors={["#064A00", "#346447", "#715800", "#BE7B3E", "#575757", "#D9D9D9", "#3B8C84"]}
                            series={[
                                {
                                    data: data,
                                    innerRadius: isMobileOnly ? window.outerWidth / 6 : 130,
                                    outerRadius: isMobileOnly ? window.outerWidth / 2.5 : 270,
                                    arcLabel: (params) => params.label + "\xa0\xa0" ?? "",
                                    arcLabelMinAngle: isMobileOnly ? 20 : 10,
                                    cx: isMobileOnly ? window.outerWidth / 2 - 35 : 350
                                }
                            ]}
                            sx={{
                                [`& .${pieArcLabelClasses.root}`]: {
                                    fill: "white",
                                    fontSize: 23,
                                    fontFamily: "Abhaya Libre"
                                }
                            }}
                            tooltip={{ trigger: "none" }}
                        />
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                                marginTop: isMobileOnly ? "40px" : "50px",
                                height: isMobileOnly ? "650px" : "520px"
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "flex-start",
                                    width: isMobileOnly ? "100%" : "400px"
                                }}
                            >
                                <div
                                    style={{
                                        minWidth: "25px",
                                        height: "25px",
                                        borderRadius: "13px",
                                        background: "#064A00",
                                        marginRight: "30px"
                                    }}
                                />
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "flex-start",
                                        height: "50px"
                                    }}
                                >
                                    <div
                                        style={{
                                            fontFamily: "Libre Caslon Text",
                                            fontSize: "24px",
                                            color: "black",
                                            fontWeight: 550
                                        }}
                                    >
                                        Purchase Price: 50.28%
                                    </div>
                                    <div
                                        style={{
                                            fontFamily: "Abhaya Libre",
                                            fontSize: "16px",
                                            color: "black",
                                            fontWeight: 550,
                                            marginTop: "15px"
                                        }}
                                    >
                                        $90,000 for the 20% stake in the $450,000 colt.
                                    </div>
                                </div>
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "flex-start",
                                    width: isMobileOnly ? "100%" : "400px"
                                }}
                            >
                                <div
                                    style={{
                                        minWidth: "25px",
                                        height: "25px",
                                        borderRadius: "13px",
                                        background: "#346447",
                                        marginRight: "30px"
                                    }}
                                />
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "flex-start",
                                        height: isMobileOnly ? "80px" : "60px"
                                    }}
                                >
                                    <div
                                        style={{
                                            fontFamily: "Libre Caslon Text",
                                            fontSize: "24px",
                                            color: "black",
                                            fontWeight: 550,
                                            lineHeight: isMobileOnly ? "30px" : "default"
                                        }}
                                    >
                                        Training Reserve: 31.91%
                                    </div>
                                    <div
                                        style={{
                                            fontFamily: "Abhaya Libre",
                                            fontSize: "16px",
                                            color: "black",
                                            fontWeight: 550,
                                            marginTop: "15px",
                                            width: isMobileOnly ? "100%" : "320px"
                                        }}
                                    >
                                        $57,118.42 for 3 years of training, care, vet, transit, and insurance.
                                    </div>
                                </div>
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "flex-start",
                                    width: isMobileOnly ? "100%" : "400px"
                                }}
                            >
                                <div
                                    style={{
                                        minWidth: "25px",
                                        height: "25px",
                                        borderRadius: "13px",
                                        background: "#715800",
                                        marginRight: "30px"
                                    }}
                                />
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "flex-start",
                                        height: "50px"
                                    }}
                                >
                                    <div
                                        style={{
                                            fontFamily: "Libre Caslon Text",
                                            fontSize: "24px",
                                            color: "black",
                                            fontWeight: 550
                                        }}
                                    >
                                        Prior Expenses: 6.64%
                                    </div>
                                    <div
                                        style={{
                                            fontFamily: "Abhaya Libre",
                                            fontSize: "16px",
                                            color: "black",
                                            fontWeight: 550,
                                            marginTop: "15px"
                                        }}
                                    >
                                        $11,891.20 for costs since purchase.
                                    </div>
                                </div>
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "flex-start",
                                    width: isMobileOnly ? "100%" : "400px"
                                }}
                            >
                                <div
                                    style={{
                                        minWidth: "25px",
                                        height: "25px",
                                        borderRadius: "13px",
                                        background: "#BE7B3E",
                                        marginRight: "30px"
                                    }}
                                />
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "flex-start",
                                        height: isMobileOnly ? "80px" : "60px"
                                    }}
                                >
                                    <div
                                        style={{
                                            fontFamily: "Libre Caslon Text",
                                            fontSize: "24px",
                                            color: "black",
                                            fontWeight: 550,
                                            lineHeight: isMobileOnly ? "30px" : "default",
                                            width: isMobileOnly ? "none" : "330px"
                                        }}
                                    >
                                        Triple Crown Trading: 5.03%
                                    </div>
                                    <div
                                        style={{
                                            fontFamily: "Abhaya Libre",
                                            fontSize: "16px",
                                            color: "black",
                                            fontWeight: 550,
                                            marginTop: "15px",
                                            width: isMobileOnly ? "100%" : "320px"
                                        }}
                                    >
                                        $9,000 for facilitating the offering, ownership perks, and management.
                                    </div>
                                </div>
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "flex-start",
                                    width: isMobileOnly ? "100%" : "400px"
                                }}
                            >
                                <div
                                    style={{
                                        minWidth: "25px",
                                        height: "25px",
                                        borderRadius: "13px",
                                        background: "#575757",
                                        marginRight: "30px"
                                    }}
                                />
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "flex-start",
                                        height: isMobileOnly ? "80px" : "60px"
                                    }}
                                >
                                    <div
                                        style={{
                                            fontFamily: "Libre Caslon Text",
                                            fontSize: "24px",
                                            color: "black",
                                            fontWeight: 550,
                                            width: isMobileOnly ? "90%" : "320px",
                                            lineHeight: isMobileOnly ? "30px" : "default"
                                        }}
                                    >
                                        Agent Commission: 2.51%
                                    </div>
                                    <div
                                        style={{
                                            fontFamily: "Abhaya Libre",
                                            fontSize: "16px",
                                            color: "black",
                                            fontWeight: 550,
                                            marginTop: "15px",
                                            width: isMobileOnly ? "90%" : "320px"
                                        }}
                                    >
                                        $4,500 for due diligence and additional management services.
                                    </div>
                                </div>
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "flex-start",
                                    width: isMobileOnly ? "100%" : "400px"
                                }}
                            >
                                <div
                                    style={{
                                        minWidth: "25px",
                                        height: "25px",
                                        borderRadius: "13px",
                                        background: "#D9D9D9",
                                        marginRight: "30px"
                                    }}
                                />
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "flex-start",
                                        height: "50px"
                                    }}
                                >
                                    <div
                                        style={{
                                            fontFamily: "Libre Caslon Text",
                                            fontSize: "24px",
                                            color: "black",
                                            fontWeight: 550
                                        }}
                                    >
                                        Woodford Fees: 2.51%
                                    </div>
                                    <div
                                        style={{
                                            fontFamily: "Abhaya Libre",
                                            fontSize: "16px",
                                            color: "black",
                                            fontWeight: 550,
                                            marginTop: "15px"
                                        }}
                                    >
                                        $4,500 for management, expertise and experiences.
                                    </div>
                                </div>
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "flex-start",
                                    width: isMobileOnly ? "100%" : "400px"
                                }}
                            >
                                <div
                                    style={{
                                        minWidth: "25px",
                                        height: "25px",
                                        borderRadius: "13px",
                                        background: "#3B8C84",
                                        marginRight: "30px"
                                    }}
                                />
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "flex-start",
                                        height: "50px"
                                    }}
                                >
                                    <div
                                        style={{
                                            fontFamily: "Libre Caslon Text",
                                            fontSize: "24px",
                                            color: "black",
                                            fontWeight: 550
                                        }}
                                    >
                                        Cash Reserve: 1.11%
                                    </div>
                                    <div
                                        style={{
                                            fontFamily: "Abhaya Libre",
                                            fontSize: "16px",
                                            color: "black",
                                            fontWeight: 550,
                                            marginTop: "15px"
                                        }}
                                    >
                                        $1,990.38 for additional incurred expenses.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        style={{
                            height: "200px",
                            marginTop: isMobileOnly ? "20px" : "0px",
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                            alignItems: "center",
                            justifyContent: "center",
                            marginBottom: isMobileOnly ? "500px" : 0
                        }}
                    >
                        <div
                            style={{
                                fontFamily: "Libre Caslon Text",
                                fontSize: "40px",
                                marginBottom: isMobileOnly ? 0 : "40px",
                                display: "flex",
                                flexDirection: "row",
                                textAlign: "center"
                            }}
                        >
                            Minimum $2,500 investment.
                        </div>
                    </div>
                </div>

                <div style={{ height: isMobileOnly ? "600px" : "500px", background: "#202409" }}>
                    <div
                        style={{
                            width: "100%",
                            padding: isMobileOnly ? "100px 30px" : "100px",
                            display: "flex",
                            flexDirection: isMobileOnly ? "column" : "row",
                            alignContent: "center",
                            justifyContent: "space-evenly"
                        }}
                    >
                        <div
                            style={{
                                color: "white",
                                fontFamily: "Libre Caslon Text",
                                fontSize: isMobileOnly ? "46px" : "72px",
                                fontWeight: 500,
                                width: isMobileOnly ? "100%" : "500px",
                                lineHeight: isMobileOnly ? "auto" : "80px",
                                textAlign: "left",
                                paddingRight: "30px"
                            }}
                        >
                            Don't wait.
                        </div>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <p
                                style={{
                                    color: "white",
                                    fontFamily: "Abhaya Libre",
                                    fontSize: "22px",
                                    fontWeight: 400,
                                    width: isMobileOnly ? "100%" : "550px",
                                    lineHeight: "normal",
                                    textAlign: "left",
                                    marginBottom: "50px",
                                    marginTop: isMobileOnly ? "50px" : 0
                                }}
                            >
                                Available now. Invest $10k+ for priority access to ownership experiences.
                            </p>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: isMobileOnly ? "column" : "row",
                                    alignContent: "center",
                                    justifyContent: "space-between",
                                    width: isMobileOnly ? "100%" : "90%"
                                }}
                            >
                                <ModalMakeOrderButton
                                    style={{
                                        alignSelf: "center",
                                        width: isMobileOnly ? "100%" : "216px",
                                        fontSize: "20px",
                                        color: "black",
                                        fontFamily: "Libre Caslon Text",
                                        textTransform: "none",
                                        fontStyle: "italic",
                                        borderRadius: "25px",
                                        boxShadow: "none",
                                        // fontWeight: 500,
                                        gap: "8px",
                                        height: "50px",
                                        background: "white"
                                    }}
                                    variant="contained"
                                    onClick={() =>
                                        (window.location.href =
                                            "https://triplecrowntrading.deal.tribexa.com/deal/create-investment")
                                    }
                                    onMouseEnter={(event) => {
                                        const target = event.target as HTMLElement;
                                        target.style.backgroundColor = "#AEAEAE";
                                    }}
                                    onMouseLeave={(event) => {
                                        const target = event.target as HTMLElement;
                                        target.style.backgroundColor = "white";
                                    }}
                                >
                                    Invest now
                                </ModalMakeOrderButton>
                                <ModalMakeOrderButton
                                    style={{
                                        alignSelf: "center",
                                        width: isMobileOnly ? "100%" : "216px",
                                        fontSize: "20px",
                                        color: "white",
                                        fontFamily: "Libre Caslon Text",
                                        textTransform: "none",
                                        fontStyle: "italic",
                                        marginTop: isMobileOnly ? "20px" : "0",
                                        borderRadius: "25px",
                                        boxShadow: "none",
                                        fontWeight: 500,
                                        gap: "8px",
                                        padding: "var(--space-xxxs, 4px) var(--space-s, 23.1px)",
                                        height: "50px"
                                    }}
                                    variant="outlined"
                                    onClick={() => dispatch(ContentActions.setContent("Contact us"))}
                                    sx={{ borderColor: "white" }}
                                    onMouseEnter={(event) => {
                                        const target = event.target as HTMLElement;
                                        target.style.borderColor = "#AEAEAE";
                                        target.style.color = "#AEAEAE";
                                    }}
                                    onMouseLeave={(event) => {
                                        const target = event.target as HTMLElement;
                                        target.style.borderColor = "white";
                                        target.style.color = "white";
                                    }}
                                >
                                    Contact us
                                </ModalMakeOrderButton>
                            </div>
                        </div>
                    </div>
                    <div
                        style={{
                            position: "static",
                            bottom: "0px",
                            width: "100%",
                            textAlign: "center",
                            fontFamily: "Abhaya Libre",
                            marginTop: "00px",
                            color: "white"
                        }}
                    >
                        © Triple Crown Trading 2024
                    </div>
                </div>
            </div>
        </>
    );
};
