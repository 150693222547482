import { produce } from "immer";
import { payloadAction, actionFactory, ActionUnion } from "reductser";

const actionMap = {
    changeColorScheme: payloadAction<"dark" | "light">()
};

export const colorActions = actionFactory(actionMap, "COLOR");

export type ColorAction = ActionUnion<typeof colorActions>;

const lightModeColorState: ColorState["scheme"] = {
    background: 'white',
    text: 'black'
};

const darkModeColorState: ColorState["scheme"] = {
    background: 'black',
    text: 'white'
};

export interface ColorState {
    mode: string;
    scheme: {
        background: string,
        text: string
    };
    general: {
        error: string,
        success: string,
        logo: string,
        darkhighlight: string,
        lighthighlight: string,
        lightaccent: string
    }
}

export const getInitialState = (): ColorState => ({
    mode: 'dark',
    scheme: darkModeColorState,
    general: {
        error: 'red',
        success: 'green',
        logo: '#82a1ee',
        darkhighlight: '#5a70c2',
        lighthighlight: '#94B2f8',
        lightaccent: '#82A1EE'
    }
});

const colorReducer = (state = getInitialState(), action: ColorAction) =>
    produce(state, (draftState) => {
        if (action.reducer === "COLOR") {
            switch (action.type) {
                case "changeColorScheme":
                    if (action.payload === "dark") {
                        draftState.mode = action.payload;
                        draftState.scheme = darkModeColorState;
                        localStorage.setItem("theme", "dark");
                    } else if (action.payload === "light") {
                        draftState.mode = action.payload;
                        draftState.scheme = lightModeColorState;
                        localStorage.setItem("theme", "light");
                    }
                    break;
                default:
                    break;
            }
        }
    });

export default colorReducer;
