import { List } from "@material-ui/core";
import { ResetTvOutlined } from "@mui/icons-material";
import { produce } from "immer";
import { payloadAction, actionFactory, ActionUnion } from "reductser";
import { MockHorseData, mockUsers } from "../../app/_shared";
import { Horse, HorseValueData, Ownership, UserAccount } from "../../types";

const actionMap = {
    setUser: payloadAction<UserAccount>(),
    addOwnership: payloadAction<Ownership>(),
};

export const UserActions = actionFactory(actionMap, "USER");

export type UserAction = ActionUnion<typeof UserActions>;

export interface UserState {
    user: UserAccount | null,
    myAssetData: HorseValueData[] | null
}

const getIndexSum = (index: number, horseList: HorseValueData[][]) : number => {
    let result = 0
    for (var horse of horseList) {
        result += horse[index]?.price
    }
    return result
}

const getAssetData = (user: UserAccount) => {
    const myOwnershipIDS = user.ownerships.map((ownership) => ownership.horse_id);
    const myOwnerships = user.ownerships;
    let myHorses : HorseValueData[][] = [];
    for (var horse of MockHorseData) {
        if(myOwnershipIDS.includes(horse.id)) {
            const filteredOwnerships = myOwnerships.filter((ownership) => ownership.horse_id == horse.id);
            const thisOwnership = filteredOwnerships[0].ownership;
            myHorses.push(horse.historical_price.map((dataPoint) => ({date: dataPoint.date, price: thisOwnership*dataPoint.price})));
        }
    }
    let result = myHorses.pop();
    result = result?.map((dataPoint : HorseValueData, index) : HorseValueData => ({date: dataPoint.date, price: (dataPoint.price + getIndexSum(index, myHorses))}))
    return result ?? null
}   

export const getInitialState = (): UserState => ({
    user: mockUsers[0],
    myAssetData: getAssetData(mockUsers[0])
});

const colorReducer = (state = getInitialState(), action: UserAction) =>
    produce(state, (draftState) => {
        if (action.reducer === "USER") {
            switch (action.type) {
                case "setUser":
                    draftState.user = action.payload;
                    break;
                case 'addOwnership':
                    if(draftState.user) {
                        let newOwnerships = draftState.user.ownerships;
                        newOwnerships.push(action.payload);
                        draftState.user.ownerships = newOwnerships;
                    }
                    break;
                default:
                    break;
            }
        }
    });

export default colorReducer;