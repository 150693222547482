import React, { useEffect, useState } from "react";
import "./style.css";
// redux
import { useDispatch } from "react-redux";
import { isMobileOnly } from "react-device-detect";
import { ContactButton, ModalMakeOrderButton } from "../_shared";
import { ContentActions } from "../../state/content";
import "react-slideshow-image/dist/styles.css";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import { TextField, createTheme, ThemeProvider } from "@mui/material";
import { styled } from "@mui/system";
import api from "../../api";
import DoneIcon from "@mui/icons-material/Done";
import CircularProgress from "@mui/material/CircularProgress";
import CloseIcon from "@mui/icons-material/Close";

//components

const CustomTextField = styled(TextField)(({ theme }) => ({
    "& .MuiOutlinedInput-root": {
        borderRadius: 0, // Square corners
        backgroundColor: "white", // Gray 200 background
        "& fieldset": {
            border: `1px solid ${theme.palette.grey[200]}` // Outlined with gray
        },
        "&:hover fieldset": {
            border: `1px solid ${theme.palette.grey[200]}` // Maintain outline on hover
        },
        "&.Mui-focused fieldset": {
            border: `1px solid ${theme.palette.grey[200]}` // Maintain outline when focused
        }
    },
    "& input": {
        fontFamily: "Abhaya Libre, sans-serif", // Abhaya Libre font
        fontSize: "20px", // 20 point font size
        color: theme.palette.grey[700] // Gray 700 text color
    },
    height: "44px", // Set height to 44 pixels
    "& .MuiInputBase-root": {
        height: "44px" // Ensure the input area is also 44 pixels high
    }
}));

const MultiCustomTextField = styled(TextField)(({ theme }) => ({
    "& .MuiOutlinedInput-root": {
        borderRadius: 0, // Square corners
        backgroundColor: "white", // Gray 200 background
        "& fieldset": {
            border: `1px solid ${theme.palette.grey[200]}` // Outlined with gray
        },
        "&:hover fieldset": {
            border: `1px solid ${theme.palette.grey[200]}` // Maintain outline on hover
        },
        "&.Mui-focused fieldset": {
            border: `1px solid ${theme.palette.grey[200]}` // Maintain outline when focused
        }
    },
    "& textarea": {
        fontFamily: "Abhaya Libre, sans-serif", // Abhaya Libre font
        fontSize: "20px", // 20 point font size
        color: theme.palette.grey[700], // Gray 700 text color
        resize: "none" // Disable resizing if you want to control the height strictly
    },
    // Set a minimum height for the input
    minHeight: "190px" // Set minimum height to 44 pixels
}));

export default (_props: any) => {
    const dispatch = useDispatch();

    useEffect(() => window.scrollTo(0, 0));

    const theme = createTheme();

    const [firstName, setFirstName] = useState<String>();
    const [lastName, setLastName] = useState<String>();
    const [email, setEmail] = useState<String>();
    const [message, setMessage] = useState<String>();
    const [sent, setSent] = useState<Boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const contactUs = async () => {
        setLoading(true);
        const response = await api.post("/public/contactUs", {
            first_name: firstName,
            last_name: lastName,
            email: email,
            message: message
        });
        if (response.data == "success") setSent(true);

        setLoading(false);
        setSent(true);
    };

    interface PasswordModalProps {
        setInvest: (value: boolean) => void;
    }

    return (
        <>
            <div className="element-c">
                <img
                    className={isMobileOnly ? "mobilelogo" : "logo"}
                    alt="Logo"
                    src="https://tctrading.s3.us-east-1.amazonaws.com/whitehorse.png"
                    onClick={() => dispatch(ContentActions.setContent("home"))}
                    style={{ cursor: "pointer", filter: 'brightness(1%)' }}
                />
                {!isMobileOnly && (
                    <>
                        <div
                            className="text-wrapper"
                            onClick={() => dispatch(ContentActions.setContent("home"))}
                            style={{ cursor: "pointer" }}
                        >
                            TRIPLE CROWN TRADING
                        </div>
                        <div className="investnowtop">
                            <ModalMakeOrderButton
                                style={{
                                    position: "fixed",
                                    right: "40px",
                                    alignSelf: "center",
                                    width: "192px",
                                    fontSize: "16px",
                                    color: "white",
                                    fontFamily: "Libre Caslon Text",
                                    textTransform: "none",
                                    fontStyle: "italic",
                                    borderRadius: "25px",
                                    boxShadow: "none",
                                    height: "40px",
                                    background: "#202409",
                                    zIndex: 20
                                }}
                                variant="contained"
                                onClick={() =>
                                    (window.location.href =
                                        "https://triplecrowntrading.deal.tribexa.com/deal/create-investment")
                                }
                                onMouseEnter={(event) => {
                                    const target = event.target as HTMLElement;
                                    target.style.backgroundColor = "black";
                                }}
                                onMouseLeave={(event) => {
                                    const target = event.target as HTMLElement;
                                    target.style.backgroundColor = "#202409";
                                }}
                            >
                                Invest now
                            </ModalMakeOrderButton>
                        </div>
                        <div className="contactustop">
                            <ContactButton
                                style={{
                                    alignSelf: "center",
                                    width: "192px",
                                    fontSize: "16px",
                                    color: "black",
                                    fontFamily: "Libre Caslon Text",
                                    textTransform: "none",
                                    // fontStyle: "italic",
                                    borderRadius: "25px",
                                    boxShadow: "none",
                                    fontWeight: 30,
                                    gap: "8px",
                                    padding: "var(--space-xxxs, 4px) var(--space-s, 23.1px)",
                                    height: "40px"
                                }}
                                variant="text"
                                onMouseEnter={(event) => {
                                    const target = event.target as HTMLElement;
                                }}
                                onMouseLeave={(event) => {
                                    const target = event.target as HTMLElement;
                                }}
                            >
                                Contact us
                            </ContactButton>
                        </div>
                    </>
                )}
                <div style={{ height: isMobileOnly ? "1200px" : "100vh" }}>
                    <div
                        style={{
                            display: "flex",
                            marginTop: isMobileOnly ? "200px" : "100px",
                            padding: isMobileOnly ? "0 20px" : "0 calc(50vw - 350px)",
                            flexDirection: "column"
                        }}
                    >
                        <div
                            style={{
                                fontFamily: "Libre Caslon Text",
                                fontSize: isMobileOnly ? "36px" : "72px",
                                textAlign: "left"
                            }}
                        >
                            Contact us
                        </div>
                        <div
                            style={{
                                fontFamily: "Abhaya Libre",
                                fontSize: "24px",
                                textAlign: "left",
                                marginTop: "50px"
                            }}
                        >
                            Shoot us an email at:
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: isMobileOnly ? "column" : "row",
                                justifyContent: "space-between",
                                width: "100%",
                                alignContent: "center",
                                marginTop: "20px"
                            }}
                        >
                            {/* <div style={{ display: "flex", flexDirection: "row" }}>
                            <LocalPhoneIcon sx={{ fontSize: 40, marginRight: "20px" }} />
                            <div style={{ fontFamily: "Abhaya Libre", fontSize: "24px", marginTop: "6px" }}>
                                1-800-111-1111
                            </div>
                        </div> */}
                            <div style={{ display: "flex", flexDirection: "row" }}>
                                <EmailIcon sx={{ fontSize: 40, marginRight: "20px" }} />
                                <div style={{ fontFamily: "Abhaya Libre", fontSize: "24px", marginTop: "6px" }}>
                                    contact@tcrowntrading.com
                                </div>
                            </div>
                        </div>
                        <div
                            style={{
                                fontFamily: "Abhaya Libre",
                                fontSize: "24px",
                                textAlign: "left",
                                marginTop: "50px"
                            }}
                        >
                            Alternatively, tell us right here:
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: isMobileOnly ? "column" : "row",
                                alignContent: "center",
                                justifyContent: "space-between"
                            }}
                        >
                            <div style={{ width: "280" }}>
                                <div
                                    style={{
                                        fontFamily: "IBM Plex mono",
                                        fontSize: "16px",
                                        color: "#575757",
                                        marginBottom: "8px",
                                        marginTop: "25px",
                                        width: "340px"
                                    }}
                                >
                                    FIRST NAME
                                </div>
                                <ThemeProvider theme={theme}>
                                    <form>
                                        <CustomTextField
                                            id="firstname"
                                            label=""
                                            variant="outlined"
                                            placeholder="John"
                                            fullWidth // Optional: makes the text field take the full width
                                            value={firstName}
                                            onChange={(event) => setFirstName(event.target.value)}
                                        />
                                    </form>
                                </ThemeProvider>
                            </div>
                            <div>
                                <div
                                    style={{
                                        fontFamily: "IBM Plex mono",
                                        fontSize: "16px",
                                        color: "#575757",
                                        marginBottom: "8px",
                                        marginTop: "25px",
                                        width: "340px"
                                    }}
                                >
                                    LAST NAME
                                </div>
                                <ThemeProvider theme={theme}>
                                    <form>
                                        <CustomTextField
                                            id="lastname"
                                            label=""
                                            variant="outlined"
                                            placeholder="Smith"
                                            fullWidth // Optional: makes the text field take the full width
                                            value={lastName}
                                            onChange={(event) => setLastName(event.target.value)}
                                        />
                                    </form>
                                </ThemeProvider>
                            </div>
                        </div>
                        <div
                            style={{
                                fontFamily: "IBM Plex mono",
                                fontSize: "16px",
                                color: "#575757",
                                marginBottom: "8px",
                                marginTop: "25px"
                            }}
                        >
                            EMAIL ADDRESS
                        </div>
                        <ThemeProvider theme={theme}>
                            <form>
                                <CustomTextField
                                    id="email"
                                    label=""
                                    variant="outlined"
                                    placeholder="name@company.com"
                                    fullWidth // Optional: makes the text field take the full width
                                    value={email}
                                    onChange={(event) => setEmail(event.target.value)}
                                />
                            </form>
                        </ThemeProvider>
                        <div
                            style={{
                                fontFamily: "IBM Plex mono",
                                fontSize: "16px",
                                color: "#575757",
                                marginBottom: "8px",
                                marginTop: "25px"
                            }}
                        >
                            MESSAGE
                        </div>
                        <ThemeProvider theme={theme}>
                            <form>
                                <MultiCustomTextField
                                    id="message"
                                    label=""
                                    variant="outlined"
                                    placeholder="Tell us what we can help you with."
                                    multiline
                                    rows={5}
                                    value={message}
                                    onChange={(event) => setMessage(event.target.value)}
                                    fullWidth // Optional: makes the text field take the full width
                                />
                            </form>
                        </ThemeProvider>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center"
                            }}
                        >
                            {!sent ? (
                                <ModalMakeOrderButton
                                    style={{
                                        alignSelf: "center",
                                        width: "216px",
                                        fontSize: "20px",
                                        color: "white",
                                        fontFamily: "Libre Caslon Text",
                                        textTransform: "none",
                                        fontStyle: "italic",
                                        borderRadius: "25px",
                                        boxShadow: "none",
                                        // fontWeight: 500,
                                        gap: "8px",
                                        height: "50px",
                                        marginTop: "20px",
                                        background: "#925F22"
                                    }}
                                    variant="contained"
                                    onClick={contactUs}
                                    disabled={loading}
                                    onMouseEnter={(event) => {
                                        const target = event.target as HTMLElement;
                                        target.style.backgroundColor = "#7A4F33";
                                    }}
                                    onMouseLeave={(event) => {
                                        const target = event.target as HTMLElement;
                                        target.style.backgroundColor = "#925F22";
                                    }}
                                >
                                    Submit
                                </ModalMakeOrderButton>
                            ) : (
                                <DoneIcon fontSize="large" style={{ color: "green" }} />
                            )}
                        </div>
                    </div>

                    <div
                        style={{
                            position: "static",
                            bottom: "0px",
                            width: "100%",
                            textAlign: "center",
                            fontFamily: "Abhaya Libre",
                            marginTop: "100px"
                        }}
                    >
                        © Triple Crown Trading 2024
                    </div>
                </div>
            </div>
        </>
    );
};
