import axios from 'axios';

// remote server

const prod = {
    url: `https://back.tctrading.co`,
    baseURL: `https://back.tctrading.co`,
}
const dev = {
    baseURL: `http://localhost:80`,
}
export default axios.create(process.env.REACT_APP_ENV == 'development' ? dev : prod);